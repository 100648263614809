import React from 'react'
import Typewriter from "typewriter-effect"
import styled from "styled-components"

const MyTitleMessage = styled.h1`
position: absolute;
width: 100%;
top: 22rem;
z-index: 1;
margin-top: -125px;
text-align: center;
strong {
  font-size: 1.25em;
}

div {
  color: white;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  font-weight: 300;
  letter-spacing: 7px;
  .main {
    font-size: 50px;
  }
  .sub {
    font-size: 30px;
    letter-spacing: 1px;
  }
}`; 

const TitleMessage = () => {
    return (
        <MyTitleMessage>
            <div className="titleMessage">
                <div className="heading">
                    <div className='main text-center mb-4'>
                        Hi, I am
                        <br />
                        <span>
                            <strong>Chris Ashman</strong>
                        </span>
                    </div>
                    <div className="sub">
                        <strong><Typewriter options={{
                            strings: ["Web Developer", "Designer", "Software Engineer"],
                            autoStart: true,
                            loop: true,
                            delay: 75
                        }} /></strong>
                    </div>
                </div>
            </div>
        </MyTitleMessage>
    )
}


export default TitleMessage
